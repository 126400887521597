export const LIST_CLOCKING_ACTION = 'LIST_CLOCKING_ACTION'
export const LIST_CLOCKING_ACTION_SUCCESS = 'LIST_CLOCKING_ACTION_SUCCESS'
export const LIST_CLOCKING_ACTION_FAILED = 'LIST_CLOCKING_ACTION_FAILED'

export const CURRENT_CLOCKING_ACTION = 'CURRENT_CLOCKING_ACTION'
export const CURRENT_CLOCKING_ACTION_SUCCESS = 'CURRENT_CLOCKING_ACTION_SUCCESS'
export const CURRENT_CLOCKING_ACTION_FAILED = 'CURRENT_CLOCKING_ACTION_FAILED'

export const ACTION_CLOCK_IN = 'ACTION_CLOCK_IN'
export const ACTION_CLOCK_IN_SUCCESS = 'ACTION_CLOCK_IN_SUCCESS'
export const ACTION_CLOCK_IN_FAILED = 'ACTION_CLOCK_IN_FAILED'

export const ACTION_CLOCK_OUT = 'ACTION_CLOCK_OUT'
export const ACTION_CLOCK_OUT_SUCCESS = 'ACTION_CLOCK_OUT_SUCCESS'
export const ACTION_CLOCK_OUT_FAILED = 'ACTION_CLOCK_OUT_FAILED'

export const SET_CLOCKING_ACTIONS = 'SET_CLOCKING_ACTIONS'

export const WORKORDER_CLOCKING_ACTIONS = 'WORKORDER_CLOCKING_ACTIONS'
export const WORKORDER_CLOCKING_ACTIONS_SUCCESS = 'WORKORDER_CLOCKING_ACTIONS_SUCCESS'
export const WORKORDER_CLOCKING_ACTIONS_FAILED = 'WORKORDER_CLOCKING_ACTIONS_FAILED'

export const EMPLOYEE_CLOCKING_ACTIONS = 'EMPLOYEE_CLOCKING_ACTIONS'
export const EMPLOYEE_CLOCKING_ACTIONS_SUCCESS = 'EMPLOYEE_CLOCKING_ACTIONS_SUCCESS'
export const EMPLOYEE_CLOCKING_ACTIONS_FAILED = 'EMPLOYEE_CLOCKING_ACTIONS_FAILED'

export const LIST_CLOCKING_ACTIONS_YEARS = 'LIST_CLOCKING_ACTIONS_YEARS'
export const LIST_CLOCKING_ACTIONS_YEARS_SUCCESS = 'LIST_CLOCKING_ACTIONS_YEARS_SUCCESS'
export const LIST_CLOCKING_ACTIONS_YEARS_FAILED = 'LIST_CLOCKING_ACTIONS_YEARS_FAILED'
